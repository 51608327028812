.topBox {
/* background: linear-gradient(180deg, #650676, #8c0f39); */
background-image: url('../../assets/galery/bambi-5.webp');
background-size: cover;
width: 100%;
background-position: left;
padding: 0px;
}

.topContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    padding: 2rem;
}

.container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    width: 100%;
    background: none !important;
}

.title {
    width: 100%;
    padding: '2rem';
    text-align: center;
}

.choosePackage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    background-color: rgba(0, 0, 0, 0.80);
    padding: 1rem;
}

.mainImage {
    position: relative;
    width: 491px;
    height: 695px;
}

.image_container {
    position: absolute;
    width: 491px;
    height: 695px;
    top: 0;
    left: 0;
    background-image: url('../../assets/price_packages/price-red-raw.jpg');
    background-size: cover;
    padding-left: 50px;
}

@media screen and (max-width: 900px) {
    .topContainer {
        padding: 0.3rem;
    }

    .choosePackage {
        padding: 0.2rem;
    }

    .container {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .mainImage {
        width: 320px;
        height: 460px;
    }

    .image_container {
        width: 320px;
        height: 460px;
        padding-left: 0;
    }
    
}