header {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    
}

.bottomHeader {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px 0px 0px;
    margin: 0px;
}