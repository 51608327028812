@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap");

* {
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding-top: 45px;
  
}

body {
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

link,
link:visited,
link:active,
a {
  color: #000000;
  text-decoration: none;
}

.demoPage, .demoPage img {
  width: 100%;
  height: 100%;
}