.top_segment {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    to right, 
    rgba(222, 122, 22, 0.22), 
    rgba(222, 122, 22, 0.7)
  );
  overflow: hidden;
}

.segment_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5px;
  max-width: 800px;
}

.container h3 {
  color: rgb(46 45 44);
  font-size: 18px;
  font-weight: 500;
}

.container ul {
    font-size: 18px;
    padding: 0px;
    text-align: left;
}

.cenovnik {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.imageContainer {
  width: 100%;
  max-width: 520px;
}

.imageContainer img {
  width: 100%;
  height: 100%;

}

@media screen and (min-width: 600px) {
  .container {
    padding: 25px;
  }
  .segment_container {
    flex-direction: row;
  }
}