.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 0px;
}

.imageContainer {
    flex: 1;
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    max-width: 400px;
}

.container h2 {
    display: flex;
    flex: 1;
    text-align: center;
    font-size: 22px;
    color: orange;
    font-weight: 400;
}

.map {
    order: 2;
    flex: 1;
    display: flex;
    width: 100%;
    max-width: 800px;
}

@media screen and (min-width: 680px) {
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 15px 0px;
    }

    .container h2 {
        display: flex;
        flex: 1;
        text-align: center;
        font-size: 22px;
        color: orange;
        font-weight: 400;
    }

    .imageContainer {
        order: 2;
    }

    .map {
        order: 1;
        display: flex;
        width: 100%;
    }
    
}