.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 900px;
  justify-content: space-evenly;
  align-items: flex-end;
  font-size: 20px;
  padding: 5px;
  position: relative;
  margin: 0px 20px 0px 10px;
}

.hamburger {
  display: block;
  color: orange;
  border: 1px solid orange;
  height: 40px;
  font-size: 24px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 25%;
  background-color: #ffffff;
  cursor: pointer;
}

.hamburgerClose {
  display: flex;
  align-items: center;
  position: absolute;
  right: 22px;
  top: 22px;
  font-size: 30px;
  width: 40px;
  color: rgb(0, 0, 0);
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: rgb(255 255 255 / 90%);
}

.navigation_menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  gap: 20px;
  top: 45px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
}

.navigation_menu ul {
  display: flex;
  flex-direction: column;
  font-size: 22px;
}
.navigation_menu li {
  list-style-type: none;
  margin: 0 0.2rem;
  min-width: 200px;
}

.navigation_menu li a {
  text-decoration: none;
  /* display: block; */
  width: 100%;
}

.activna,
.notActive {
  display: flex;
  align-items: center;
  padding: 10px;
}

.activna {
  color: #946104;
  text-decoration: none;
}

.notActive {
  color: rgb(7, 8, 8);
}

.navIcons {
  font-size: 25px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 680px) {
  .container {
    display: flex;
    flex: 2;
    flex-direction: row;
  }
  .hamburger {
    display: none;
  }
  .navigation_menu {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    margin: 0px;
    top: 0;
    left: 0;
    height: auto;
    padding: 0;
    z-index: 1;
    background-image: none;
  }
  .hamburgerClose {
    display: none;
  }
  .navigation_menu ul {
    display: flex;
    flex-direction: row;
    position: relative;
    height: auto;
  }
}

@media screen and (min-width: 800px) {
  .container {
    flex: 3;
  }
}
