.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.containerImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 5px;
    max-width: 800px;
}

.container ul {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 800px;
    font-size: 18px;
    padding: 0px;
}

.container ul li {
    display: flex;
    flex: 1;
    padding: 5px;
    align-items: center;
    border-bottom: 1px dotted gray;
}

.leftSpan {
    width: calc(100% - 100px);
}

.rightSpan {
    margin-left: auto;
    width: 88px;
}

.container h1 {
    flex: 1;
    text-align: center;
    font-size: 25px;
    color: orange;
    padding: 15px 0px;
    margin: 2px;
}

.container h2 {
    text-align: center;
    background-color: orange;
    color: white;
    padding: 10px;
    margin: 5px;
}

.container img {
    width: 100%;
    height: 100%;
}

.bottomUL li {
    border-bottom: none;
}
