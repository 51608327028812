.container {
    width: 170px;
    height: auto;
    padding: 0px;
    margin: 0px 10%;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.container img {
    width: 100%;
    height: 100%;
}
@media screen and (min-width: 680px) {
    .container {
        width: 200px;
        height: auto;
        padding: 0px;
        margin: 0px 10%;

    }
}

@media screen and (min-width: 800px) {
    .container {
        width: 250px;
        height: auto;

    }
}

