.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background: linear-gradient(to top, rgba(222, 122, 22, 0.22), rgba(222, 122, 22, 0.7));
}

.containerHome {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background: linear-gradient(to top, rgba(222, 122, 22, 0.22), rgba(222, 122, 22, 0.7));
}

.wraper {
  display: flex;
  justify-content: center;
  flex: 1 1 100%;
  flex-wrap: wrap;
  padding: 15px;
  min-height: 500px;
}

.imageContainer {
  flex: 1 1 500px;
  max-width: 500px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem 1rem 1rem;
  margin: 1rem;
}

.imageContainerHome {
  max-width: 500px;
  height: auto;
  display: flex;
  flex: 2 1 500px;
  justify-content: center;
  align-items: center;
  padding: 0 1rem 1rem 1rem;
  margin: 1rem;
}

.imageContainer img, .imageContainerHome img {
  width: 100%;
  height: auto;
  border-radius: 35px;
}

.buttonCateringHome {
  display: flex;
  max-width: 250px;
  align-items: center;
  justify-content: center;
  flex: 1 1 150px;
}

.title {
  max-width: 500px;
  flex: 1 1 500px;
  color: white;
  padding: 1rem;
  background-color: rgba( 128, 5, 128, 0.45);
  border-radius: 2rem;
}