.container {
    width: fit-content;
    height: 200px;
    border-radius: 10%;
    box-shadow: 1px 1px 1px 1px #acacac;
    margin: 10px auto;
    overflow: hidden;
}

.container img {
    width: 100%;
    height: 100%;
}